import React from 'react';
import logo from './logo.svg';
import './App.css';
import Menu from './components/content/menu';
import Main from './components/content/main';
import Rosto from './components/content/rosto';
import Servicos from './components/content/servicos';
import Instrutores from './components/content/instrutores';
import Footer from './components/content/footer';
import Local from './components/content/local';
import Avaliacoes from './components/content/avaliacoes';
import Valores from './components/content/valores';
import Espaco from './components/content/espaco';
import { useEffect } from 'react';
import { useState } from 'react';



function App() {

  const[ativaCor, setAtivaCor] = useState(false);

  useEffect(function(){
    function posicaoScroll(){
      if(window.scrollY > 20){
        setAtivaCor(true);
      }else{
        setAtivaCor(false);
      }
    }

    window.addEventListener('scroll', posicaoScroll);
  }, []);

  return (
    <div className="App">
      <Menu acao={ativaCor}/>
      <Rosto/>
        <div className="content">
        <Main/>        
        <Instrutores/>     
        <Espaco/>               
        <Servicos/> 
        <Local/> 
        <Avaliacoes/>   
      </div>   
      <Footer/>
    </div>
  );
}

export default App;
