import React from 'react';
import './cardColor.scss'

function CardColor(props: { color: string, title : string, text : string, image : string}) {

    return(   
        <div className={props.color}>
            <h2>{props.title}</h2>                
            <img className="image" src={props.image} alt={props.title} />
            <p>{props.text}</p>
        </div>
    );
}

export default CardColor;