import React from 'react';
import './style.scss'
import Logo from "../../assets/imagens/logo_corpo_e_alma.png";

class Rosto extends React.Component{
    render(){
        return(
            <div className="menu">
                <div className="logo">
                    <img src={Logo} alt="Studio Corpo & Alma"></img>                    
                </div>
                 
                <div className="btn">
                    <input type="button"   onClick={(e) => {
                            e.preventDefault();
                            window.open("https://wa.me/551123660975?text=Oi!%20Gostaria%20de%20receber%20atendimento.", "_blank")
                        }} className="btnAgendar" value="Agende uma aula experimental grátis"/>
                </div>
       
            </div>              
        )
    }
}

export default Rosto;