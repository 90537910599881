import React from 'react';
import './style.scss';
import StudioImg from '../../assets/imagens/studio.png';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import CheckIcon from '@mui/icons-material/Check';
import ImgPilates from '../../assets/imagens/servicos/pilates.jpg';
import ImgSaude from '../../assets/imagens/beneficios/1.png';
import ImgDor from '../../assets/imagens/beneficios/2.png';
import ImgQualidade from '../../assets/imagens/beneficios/4.png';
import ImgDesempenho from '../../assets/imagens/beneficios/3.png';
import ImgKinesio from '../../assets/imagens/servicos/kinesio.jpg';
import ImgVentosa from '../../assets/imagens/servicos/ventosaterapia.jpg';
import ImgAcupuntura from '../../assets/imagens/servicos/acupuntura.jpg';
import ImgSeparator from '../../assets/imagens/separator.svg';
import CardItem from '../CardItem/cardItem';

class Main extends React.Component{
    render(){
        return(
            <div>
                <div className="tituloInicio">
                    <h2 className='titgrey'>Porque fazer pilates</h2>
                    <img className='separator' src={ImgSeparator}></img>
                    <h1 className='titgreen'>O que você busca?</h1>                
                </div>
                <div className="row">                
                    <CardItem color="cardItem" 
                        image={ImgSaude}                     
                        title="Melhora a saúde"
                        text="Melhorar tônus muscular, flexibilidade e postura." 
                    />     
                    <CardItem color="cardItem" 
                        image={ImgDor}                     
                        title="Alívio das Dores"
                        text="Você não precisa conviver com dor. De adeus as dores que te incomodam dia e noite." 
                    />  
                    <CardItem color="cardItem" 
                        image={ImgQualidade}                     
                        title="Qualidade de vida"
                        text="Alívio do stress, melhora a disposição e autoestima." 
                    /> 
                    <CardItem color="cardItem" 
                        image={ImgDesempenho}                     
                        title="Desempenho"
                        text="Otimiza o desempenho das atividades diárias e de outras atividades físicas." 
                    />              
                </div>
            </div>           
        )
    }
}

export default Main;