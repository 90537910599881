import React from 'react';
import './cardItem.scss'

function CardItem(props: { color: string, title : string, text : string, image : string}) {

    return(   
        <div className={props.color}>
            <img className="imageCard" src={props.image} alt={props.title} />
            <h2>{props.title}</h2>                            
            <p>{props.text}</p>
        </div>
    );
}

export default CardItem;