import React from 'react';
import './instrutores.scss'
import ImgAmandaInterlick from '../../assets/imagens/profissionais/amanda.jpg';
import ImgLogo from '../../assets/imagens/logo.svg';
function Instrutores() {

    return(
        <section id="instrutores">
            <div className='leftImgMobile'></div>
            <div className="instrutores">
                
                <div className='leftImg'></div>

                <div className="rightBoard">
                    <div className='logoText'>
                        <p className='dizeres'>"O Studio Corpo & Alma foi totalmente planejado para entregar resultado aos nossos pacientes."</p>
                        <h1 className='titulo'>VENHA FAZER PILATES</h1>                    
                    </div>
                    
                    <input type="button"   onClick={(e) => {
                                e.preventDefault();
                                window.open("https://wa.me/551123660975?text=Oi!%20Gostaria%20de%20receber%20atendimento.", "_blank")
                    }} className="btnAgendarInst" value="Agende uma aula experimental grátis"/>

                </div> 
            </div>            
        </section>
    );
}

export default Instrutores;