import React from 'react';
import './local.scss';
import ImgSeparator from '../../assets/imagens/separator.svg';
function Local() {

    return(
        <section id="localizacao" className="mapa">
            <div className="tituloInicio">
                    <h2 className='titgrey'>Localização</h2>
                    <img className='separator' src={ImgSeparator}></img>
                    <h1 className='titgreen'>Onde estamos</h1>                
            </div>
            <p className='pLocal'>Endereço: Avenida Mutinga, 239 - Sala 04<br/>Pirituba - São Paulo - SP<br/>CEP: 05154-000</p>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.2118653144976!2d-46.73169878558764!3d-23.488877164803355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef93e39318cd3%3A0xa8cea6a858303cdc!2sAv.%20Mutinga%2C%20239%20-%20Jardim%20Libano%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2005154-000!5e0!3m2!1spt-BR!2sbr!4v1654025609701!5m2!1spt-BR!2sbr" width="800" height="600" style={{ border: 0 }}  loading="lazy"></iframe>
        </section>
    );
}

export default Local;