import React from 'react';
import './avaliacoes.scss';
import ImgSeparator from '../../assets/imagens/separator.svg';
function Local() {

    return(
        <section id="avaliacoes" className="avaliacoes">

            <div className="tituloInicio">
                    <h2 className='titgrey'>Feedback</h2>
                    <img className='separator' src={ImgSeparator}></img>
                    <h1 className='titgreen'>Depoimentos dos nossos alunos</h1>                
            </div>                
            <iframe className='reviews' src="https://9237d98e74e04a8bafba516ac0b9b511.elf.site/" height="350px" width="100%"></iframe>

        </section>
    );
}

export default Local;