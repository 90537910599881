import React from 'react';
import './footer.scss';
import WhatsappIcon from '../../assets/icons/whatsapp.svg';
import InstagramIcon from '../../assets/icons/instagram96.svg';
function Footer() {

    return(
        <footer className="footer">
            <div className="footer-content">
                <h3>Studio Corpo & Alma</h3>
                <p>Saúde e bem estar</p>
                <ul className="contato">                                                    
                <li><a href="https://wa.me/551123660975?text=Oi!%20Gostaria%20de%20receber%20atendimento." target="_blank"><i className="fa fa-whatsapp"></i>(11) 2366-0975</a></li>                
                </ul>                 
                <ul className="socials">                                    
                    <li><a href="http://www.instagram.com/studio.corpoealma/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                    <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i className="fa fa-linkedin-square"></i></a></li>
                </ul>                
            </div>
            <div className="footer-bottom">
                <p>Copyright &copy;2022 Studio Corpo & Alma.<br/>Desenvolvido por <span><a href="http://www.aenweb.com.br" target="_blank">A&N Web</a></span></p>
            </div>

            <a className="whatsapp" href="https://wa.me/551123660975?text=Oi!%20Gostaria%20de%20receber%20atendimento." target="_blank">
                <img src={WhatsappIcon} alt="Fale Conosco pelo WhatsApp" />
            </a>
            <a className="instagram" href="https://www.instagram.com/studio.corpoealma/" target="_blank">
                <img src={InstagramIcon} alt="Instagram" />
            </a>

        </footer>
    );
}

export default Footer;