import React from 'react';
import './servicos.css';
import ImgPilates from '../../assets/imagens/servicos/pilates.jpg';
import ImgKinesio from '../../assets/imagens/servicos/kinesio.jpg';
import ImgVentosa from '../../assets/imagens/servicos/ventosaterapia.jpg';
import ImgAcupuntura from '../../assets/imagens/servicos/acupuntura.jpg';
import CardColor from '../CardColor/cardColor';
import ImgSeparator from '../../assets/imagens/separator.svg';

function Servicos() {
    return(
        <section id="servicos">
                         
            <div className="tituloInicio">
                    <h2 className='titgrey'>Serviços</h2>
                    <img className='separator' src={ImgSeparator}></img>
                    <h1 className='titgreen'>Alguns dos nossos métodos e terapias</h1>               
                </div>

            <div className="row">                
                <CardColor color="card green" 
                    image={ImgPilates}                     
                    title="Pilates"
                    text="Pilates é um conjunto de exercícios realizados no solo ou em equipamentos, que visa trabalhar a conexão entre mente e corpo, melhorando a consciência corporal, proporcionando diversos benefícios." 
                />
            
                <CardColor color="card blue" 
                    image={ImgAcupuntura}                     
                    title="Acupuntura"
                    text="A acupuntura é uma terapia milenar originária da China, que consiste na aplicação de agulhas em pontos específicos do corpo para tratar doenças fisicas ou emocionais e para promover saúde e bem estar." 
                />        

                <CardColor color="card red" 
                    image={ImgVentosa}                     
                    title="Ventosaterapia"
                    text="Técnica terapeutica que envolve a aplicação de ventosas, utilizada para liberar tensões, redução de inflamações, assim reduzindo dores e equilibrando o corpo a mente, através do estimulo da circulação." 
                />     
            </div>

            
        </section>
    );
}

export default Servicos;