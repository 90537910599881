import React from 'react';
import {useState, useEffect } from 'react';
import './espaco.scss'
import ImgEspaco1 from '../../assets/imagens/espaco/espaco1.jpg';
import ImgEspaco2 from '../../assets/imagens/espaco/espaco2.jpg';
import ImgEspaco3 from '../../assets/imagens/espaco/espaco3.jpg';
import ImgEspaco4 from '../../assets/imagens/espaco/espaco4.jpg';
import ImgEspaco5 from '../../assets/imagens/espaco/espaco5.jpg';
import ImgEspaco6 from '../../assets/imagens/espaco/espaco6.jpg';
import ImgEspaco7 from '../../assets/imagens/espaco/espaco7.jpg';
import ImgSeparator from '../../assets/imagens/separator.svg';
import {Swiper, SwiperSlide} from 'swiper/react';

function Espaco() {

    const [slidePerView, setSlidePerView] = useState(3);

    const data = [
        { id: '1', image: ImgEspaco1, desc: 'Espaço amplo e bem iluminado'},
        { id: '2', image: ImgEspaco2, desc: 'Espaço amplo e bem iluminado'},
        { id: '3', image: ImgEspaco3, desc: 'Espaço amplo e bem iluminado'},
        { id: '4', image: ImgEspaco4, desc: 'Espaço amplo e bem iluminado'},
        { id: '5', image: ImgEspaco5, desc: 'Espaço amplo e bem iluminado'},
        { id: '6', image: ImgEspaco6, desc: 'Espaço amplo e bem iluminado'},
        { id: '7', image: ImgEspaco7, desc: 'Espaço amplo e bem iluminado'},
    ];

    useEffect(() => {
        function handleResize(){
            if(window.innerWidth < 720) {
                setSlidePerView(1);
            }
            else{
                setSlidePerView(3);
            }
        }

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    return(
        <section id="espaco" className="espaco">
           
            <div className="tituloInicio">
                    <h2 className='titgrey'>Espaço</h2>
                    <img className='separator' src={ImgSeparator}></img>
                    <h1 className='titgreen'>Conheça nosso espaço</h1>                
            </div>

            <p className='espacoDesc'>
                Ambiente climatizado <b className='sepGreen'>|</b> Sala ampla <b className='sepGreen'>|</b> Profissionais treinados e altamente capacitados <b className='sepGreen'>|</b> Melhores aparelhos do mercado
            </p>
            <p className='espacoDesc'>Máximo 4 pessoas por horário <b className='sepGreen'>|</b> Atendimento direcionado para os seus objetivos <b className='sepGreen'>|</b> Flexibilidade para agendamentos
            </p>
            <br/><br/>

            <Swiper 
                slidesPerView={slidePerView} 
                pagination={{clickable: true}}
                navigation
            >
                { data.map( (item) => (
                    <SwiperSlide key={item.id}>
                        <img src={item.image} alt={item.desc} className="slide-item" ></img>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
}

export default Espaco;