import React, {useState} from 'react';
import './menu.scss'
import LogoInstagram from '../../assets/icons/instagram.png';
import LogoWhatsapp from '../../assets/icons/whatsapp.png';
import LogoFacebook from '../../assets/icons/facebook.png';
import LogoLinkedin from '../../assets/icons/linkedin.png';

function Menu({acao} : {acao: boolean}) {

    const [active, setActive] = useState("nav-menu");
    const [toggleIcon, setToggleIcon] = useState("nav-toggler");
    const navToggle = () => {
        active === "nav-menu" ? setActive("nav-menu nav-active") : setActive("nav-menu");
        
        //TogglerIcon
        toggleIcon === "nav-toggler" ? setToggleIcon("nav-toggler toggle") : setToggleIcon("nav-toggler")
    };

    return(
        <header className={ acao ? 'ativaCor' : '' }>
            <nav className= { acao ? 'ativaCor' : '' }>
                <ul className={active}>
                <li><a href="#">Sobre</a></li>
                <li><a href="#servicos">Serviços</a></li>
                <li><a href="#instrutores">Profissionais</a></li>
                <li><a href="#localizacao">Localização</a></li>
                </ul>
                <div onClick={navToggle} className={toggleIcon}>
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
            </nav>
        </header>
    );
}

export default Menu;